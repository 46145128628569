<template>
  <div>
    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

    <h4 qid="retention-rules-page-title" v-if="getClient.processingActivitiesModule">
      {{ $t('maintenance.reports.core_title') }}
    </h4>
    <b-alert v-if="error.length"
             show
             variant="danger"
             qid="create-do-error"
             class="mt-3"
    >
      {{ error }}
    </b-alert>
    <b-alert v-if="successMessage.length"
             show
             variant="success"
             qid="create-report-message"
             class="mt-3"
             dismissible
    >
      {{ successMessage }}
    </b-alert>
    <div v-if="!disablePage">

      <div class="row mt-4">

        <div class="col-lg-6 col-md-12" v-if="getClient.processingActivitiesModule">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openProcessModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.process_overview_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.process_overview_report_description')}}</p>
              <div v-if="processOverviewReport && processOverviewReport.id" class="mt-2">
                <a @click="downloadMedia(processOverviewReport)" qid="download_process_overview_report">
                  {{ $t('maintenance.reports.download') }}
                </a>
                <span class="text-muted mt-3 ml-1">
                  {{ $t('maintenance.reports.latest_report_date_created') }} {{processOverviewReport.created_at | formatDateTime}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12" v-if="getClient.systemModule">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openSystemModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.system_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.system_report_description')}}</p>
              <div v-if="systemReport && systemReport.id" class="mt-2">
                <a @click="downloadMedia(systemReport)" qid="download_system_report">
                  {{ $t('maintenance.reports.download') }}
                </a>
                <span class="text-muted mt-3 ml-1">
                  {{ $t('maintenance.reports.latest_report_date_created') }} {{systemReport.created_at | formatDateTime}}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12" v-if="getClient.contractAdvancedModule">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openThirdPartyModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.third_party_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.third_party_report_description')}}</p>
              <div v-if="thirdPartyReport && thirdPartyReport.id" class="mt-2">
                <a @click="downloadMedia(thirdPartyReport)" qid="download_third_party_report">
                  {{ $t('maintenance.reports.download') }}
                </a>
                <span class="text-muted mt-3 ml-1">
                  {{ $t('maintenance.reports.latest_report_date_created') }} {{thirdPartyReport.created_at | formatDateTime}}
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <h4 qid="retention-rules-page-title" class="mt-5" v-if="getClient.gdprModule && getClient.processingActivitiesModule">
        {{ $t('maintenance.reports.gdpr_title') }}
      </h4>

      <div class="row mt-4">
        <div class="col-lg-6 col-md-12" v-if="getClient.gdprModule && getClient.processingActivitiesModule && getClient.contractBasicModule">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openArticleModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.article_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.article_report_description')}}</p>
              <div v-if="articleReport && articleReport.id" class="mt-2">
                <a @click="downloadMedia(articleReport)" qid="download_article_report">
                  {{ $t('maintenance.reports.download') }}
                </a>
                <span class="text-muted mt-3 ml-1">
                  {{ $t('maintenance.reports.latest_report_date_created') }} {{articleReport.created_at | formatDateTime}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" v-if="getClient.gdprAdvancedModule && getClient.processingActivitiesModule">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openProcessRiskModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.process_risk_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.process_risk_report_description')}}</p>
              <div v-if="processRiskReport && processRiskReport.id" class="mt-2">
                <a @click="downloadMedia(processRiskReport)" qid="download_process_risk_report">
                  {{ $t('maintenance.reports.download') }}
                </a>
                <span class="text-muted mt-3 ml-1">
                  {{ $t('maintenance.reports.latest_report_date_created') }} {{processRiskReport.created_at | formatDateTime}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 qid="retention-rules-page-title" class="mt-5" v-if="getClient.riskModule">
        {{ $t('maintenance.reports.risk_control_title') }}
      </h4>

      <div class="row mt-4" v-if="getClient.riskModule">
        <div class="col-lg-6 col-md-12" v-if="getClient.riskModule && (getClient.processingActivitiesModule || getClient.contractBasicModule)">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openRiskModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.risk_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.risk_report_description')}}</p>
              <div class="row mb-2">
                <div v-if="riskReport && riskReport.id" class="mt-2 col-md-8">
                  <a @click="downloadMedia(riskReport)" qid="download_article_report">
                    {{ $t('maintenance.reports.download') }}
                  </a>
                  <span class="text-muted mt-3 ml-1">
                    {{ $t('maintenance.reports.latest_report_date_created') }} {{riskReport.created_at | formatDateTime}}
                  </span>
                </div>
                <div v-if="appendixRiskReport && appendixRiskReport.id" class="mt-2 col-md-4">
                  <a @click="downloadMedia(appendixRiskReport)" qid="download_appendix_risk_report">
                    {{ $t('maintenance.reports.download') }}
                  </a>
                  <span class="text-muted mt-3 ml-1">
                    {{ $t('maintenance.reports.latest_report_appendix') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12" v-if="getClient.riskModule">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openSoaModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.soa_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.soa_report_description')}}</p>
              <div class="row mb-2">
                <div v-if="soaReport && soaReport.id" class="mt-2 col-md-8">
                  <a @click="downloadMedia(soaReport)" qid="download_soa_report">
                    {{ $t('maintenance.reports.download') }}
                  </a>
                  <span class="text-muted mt-3 ml-1">
                    {{ $t('maintenance.reports.latest_report_date_created') }} {{soaReport.created_at | formatDateTime}}
                  </span>
                </div>
                <div v-if="appendixSoaReport && appendixSoaReport.id" class="mt-2 col-md-4">
                  <a @click="downloadMedia(appendixSoaReport)" qid="download_appendix_soa_report">
                    {{ $t('maintenance.reports.download') }}
                  </a>
                  <span class="text-muted mt-3 ml-1">
                    {{ $t('maintenance.reports.latest_report_appendix') }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-4"  v-if="getClient.riskModule">
        <div class="col-lg-6 col-md-12">
          <div class="card card-modern mt-3 min-height-150">
            <div class="card-gradient"></div>
            <div class="">
              <span class="mr-3 btn btn-success float-right cursor-pointer"
                    @click="openFrameworkModal()"
                    qid="download-media"
              >
                {{ $t('maintenance.reports.generate_new') }}
                <font-awesome-icon icon="cloud-download-alt" class="ml-2"/>
              </span>
              <h5 class="mb-0">{{$t('reports.framework_implementation_report')}}</h5>
              <p class="mt-4 mb-4">{{$t('reports.framework_implementation_report_description')}}</p>
              <div class="row mb-2">
                <div v-if="appendixImplementationReport && appendixImplementationReport.id" class="mt-2 col-md-8">
                  <a @click="downloadMedia(appendixImplementationReport)" qid="download_framework_report">
                    {{ $t('maintenance.reports.download') }}
                  </a>
                  <span class="text-muted mt-3 ml-1">
                    {{ $t('maintenance.reports.latest_report_date_created') }} {{appendixImplementationReport.created_at | formatDateTime}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="card text-center" v-if="disablePage">
      <div class="card-body">
        <div class="mt-5 mb-5">
          <h1>
            <font-awesome-icon icon="spinner" class="fa-spin" />
          </h1>
          <h3 class="mt-3">
            {{ loadingMessage }}
          </h3>
        </div>
      </div>
    </div>


    <!-- Process report modal -->
    <b-modal ref="process-modal" hide-footer @hide="closeProcessModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.process_overview_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="processScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              @select="resetObjects"
              label="name"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_owner'">
          <label qid="create-p-users-label">
            {{ $t('processes.create.owners_label') }}
          </label>
          <multiselect
              v-model="selectedUsers"
              :options="users"
              :multiple="true"
              :taggable="false"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              @search-change="loadUsers"
              track-by="slug"
              :placeholder="$t('processes.create.owners_placeholder')"
              label="name"
              qid="create-p-users-select"
          >
            <template slot="tag" slot-scope="props">
              <Avatar :object="props.option" size="xs"></Avatar>
              <span class="ml-2">
                {{ props.option.name }}
              </span>
            </template>
            <template slot="option" slot-scope="props">

            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_group'">
          <label qid="create-p-pg-label">
            {{ $t('processes.create.process_group_label') }}
          </label>
          <multiselect
              v-model="selectedProcessGroups"
              :options="processGroups"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcessGroups"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('processes.create.process_group_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.process_label') }}
          </label>
          <multiselect
              v-model="selectedProcesses"
              :options="processes"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcesses"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.process_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeProcessModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadProcessOverviewFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- System report modal -->
    <b-modal ref="system-modal" hide-footer @hide="closeSystemModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.system_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="systemScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              label="name"
              @select="resetObjects"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'system_owner'">
          <label qid="create-p-users-label">
            {{ $t('processes.create.owners_label') }}
          </label>
          <multiselect
              v-model="selectedUsers"
              :options="users"
              :multiple="true"
              :taggable="false"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              @search-change="loadUsers"
              track-by="slug"
              :placeholder="$t('processes.create.owners_placeholder')"
              label="name"
              qid="create-p-users-select"
          >
            <template slot="tag" slot-scope="props">
              <Avatar :object="props.option" size="xs"></Avatar>
              <span class="ml-2">
                {{ props.option.name }}
              </span>
            </template>
            <template slot="option" slot-scope="props">

            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'system_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.system_label') }}
          </label>
          <multiselect
              v-model="selectedSystems"
              :options="systems"
              :multiple="true"
              :taggable="false"
              @search-change="loadSystems"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.system_label')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeSystemModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadSystemFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Third party report modal -->
    <b-modal ref="third-party-modal" hide-footer @hide="closeThirdPartyModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.third_party_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <label qid="create-ac-pg-label">
            {{ $t('activities.processing_role.title') }}
          </label>
          <multiselect
                  v-model="processingRoles"
                  :options="processingRoleOptions"
                  :multiple="true"
                  :placeholder="$t('activities.processing_role.pr_placeholder')"
                  label="name"
                  :select-label="$t('system.dropdown_select_label')"
                  :selected-label="$t('system.dropdown_selected_label')"
                  :deselect-label="$t('system.dropdown_deselect_label')"
                  track-by="name"
                  qid="processing-role-dropdown"
          >
            <template slot="option" slot-scope="props">
              <span v-if="props.option.value == 'Data processor'">
                <font-awesome-icon icon="microchip" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Data controller'">
                <font-awesome-icon icon="joystick" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Joint controller'">
                <font-awesome-icon icon="handshake" class="mr-2"/>
              </span>
              {{ $t('maintenance.third_parties.processing_role.' + $options.filters.slugify(props.option.value)) }}
            </template>
            <template slot="singleLabel" slot-scope="props">
              <span v-if="props.option.value == 'Data processor'">
                <font-awesome-icon icon="microchip" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Data controller'">
                <font-awesome-icon icon="joystick" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Joint controller'">
                <font-awesome-icon icon="handshake" class="mr-2"/>
              </span>
              {{ $t('maintenance.third_parties.processing_role.' + $options.filters.slugify(props.option.value)) }}
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="thirdPartyScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              label="name"
              @select="resetObjects"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'third_party_owner'">
          <label qid="create-p-users-label">
            {{ $t('processes.create.owners_label') }}
          </label>
          <multiselect
              v-model="selectedUsers"
              :options="users"
              :multiple="true"
              :taggable="false"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              @search-change="loadUsers"
              track-by="slug"
              :placeholder="$t('processes.create.owners_placeholder')"
              label="name"
              qid="create-p-users-select"
          >
            <template slot="tag" slot-scope="props">
              <Avatar :object="props.option" size="xs"></Avatar>
              <span class="ml-2">
                {{ props.option.name }}
              </span>
            </template>
            <template slot="option" slot-scope="props">

            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'third_party_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.third_party_label') }}
          </label>
          <multiselect
              v-model="selectedThirdParties"
              :options="thirdParties"
              :multiple="true"
              :taggable="false"
              @search-change="loadThirdParties"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.third_party_label')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeThirdPartyModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadThirdPartyFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Article report modal -->
    <b-modal ref="article-modal" hide-footer @hide="closeArticleModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.article_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">

        <b-form-group>
          <label qid="create-ac-pg-label">
            {{ $t('activities.processing_role.title') }}
          </label>
          <multiselect
              v-model="processingRoles"
              :options="processingRoleOptions"
              :multiple="true"
              :placeholder="$t('activities.processing_role.pr_placeholder')"
              label="name"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="name"
              qid="processing-role-dropdown"
          >
            <template slot="option" slot-scope="props">
              <span v-if="props.option.value == 'Data processor'">
                <font-awesome-icon icon="microchip" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Data controller'">
                <font-awesome-icon icon="joystick" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Joint controller'">
                <font-awesome-icon icon="handshake" class="mr-2"/>
              </span>
              {{ $t('maintenance.third_parties.processing_role.' + $options.filters.slugify(props.option.value)) }}
            </template>
            <template slot="singleLabel" slot-scope="props">
              <span v-if="props.option.value == 'Data processor'">
                <font-awesome-icon icon="microchip" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Data controller'">
                <font-awesome-icon icon="joystick" class="mr-2"/>
              </span>
              <span v-if="props.option.value == 'Joint controller'">
                <font-awesome-icon icon="handshake" class="mr-2"/>
              </span>
              {{ $t('maintenance.third_parties.processing_role.' + $options.filters.slugify(props.option.value)) }}
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="processScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              label="name"
              @select="resetObjects"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_owner'">
          <label qid="create-p-users-label">
            {{ $t('processes.create.owners_label') }}
          </label>
          <multiselect
              v-model="selectedUsers"
              :options="users"
              :multiple="true"
              :taggable="false"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              @search-change="loadUsers"
              track-by="slug"
              :placeholder="$t('processes.create.owners_placeholder')"
              label="name"
              qid="create-p-users-select"
          >
            <template slot="tag" slot-scope="props">
              <Avatar :object="props.option" size="xs"></Avatar>
              <span class="ml-2">
                {{ props.option.name }}
              </span>
            </template>
            <template slot="option" slot-scope="props">

            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_group'">
          <label qid="create-p-pg-label">
            {{ $t('processes.create.process_group_label') }}
          </label>
          <multiselect
              v-model="selectedProcessGroups"
              :options="processGroups"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcessGroups"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('processes.create.process_group_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.process_label') }}
          </label>
          <multiselect
              v-model="selectedProcesses"
              :options="processes"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcesses"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.process_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeArticleModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadArticleFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Process risk report modal -->
    <b-modal ref="process-risk-modal" hide-footer @hide="closeProcessRiskModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.process_risk_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="processScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              label="name"
              @select="resetObjects"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_owner'">
          <label qid="create-p-users-label">
            {{ $t('processes.create.owners_label') }}
          </label>
          <multiselect
              v-model="selectedUsers"
              :options="users"
              :multiple="true"
              :taggable="false"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              @search-change="loadUsers"
              track-by="slug"
              :placeholder="$t('processes.create.owners_placeholder')"
              label="name"
              qid="create-p-users-select"
          >
            <template slot="tag" slot-scope="props">
              <Avatar :object="props.option" size="xs"></Avatar>
              <span class="ml-2">
                {{ props.option.name }}
              </span>
            </template>
            <template slot="option" slot-scope="props">

            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_group'">
          <label qid="create-p-pg-label">
            {{ $t('processes.create.process_group_label') }}
          </label>
          <multiselect
              v-model="selectedProcessGroups"
              :options="processGroups"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcessGroups"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('processes.create.process_group_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.process_label') }}
          </label>
          <multiselect
              v-model="selectedProcesses"
              :options="processes"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcesses"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.process_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeProcessRiskModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadProcessRiskFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Risk report modal -->
    <b-modal ref="risk-modal" hide-footer @hide="closeRiskModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.risk_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <label qid="edit-p-implementation-status-label">
            {{ $t('reports.modal.risk_type_label') }}
          </label>
          <multiselect
              v-model="selectedRiskType"
              :options="riskTypes"
              :placeholder="$t('reports.modal.risk_type_placeholder')"
              label="name"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              qid="edit-p-form-implementation-status"
          ></multiselect>
        </b-form-group>
        <b-form-group>
          <label qid="edit-p-implementation-status-label">
            {{ $t('reports.modal.scope_area_label') }}
          </label>
          <multiselect
              v-model="selectedScopeArea"
              :options="scopeAreas"
              :placeholder="$t('reports.modal.scope_area_placeholder')"
              label="name"
              @select="selectedScopeType=[]"
              @remove="selectedScopeType=[]"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              qid="edit-p-form-implementation-status"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeArea && selectedScopeArea.value && selectedScopeArea.value == 'process'">
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="processScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              @select="resetObjects"
              label="name"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeArea && selectedScopeArea.value && selectedScopeArea.value == 'activity'">
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="activityScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              @select="resetObjects"
              label="name"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeArea && selectedScopeArea.value && selectedScopeArea.value == 'system'">
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="systemScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              @select="resetObjects"
              label="name"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeArea && selectedScopeArea.value && selectedScopeArea.value == 'third_party'">
          <label qid="create-ac-pg-label">
            {{$t('reports.modal.scope_type_label')}}
          </label>
          <multiselect
              v-model="selectedScopeType"
              :options="thirdPartyScopeTypes"
              :placeholder="$t('reports.modal.scope_type_placeholder')"
              @select="resetObjects"
              label="name"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              track-by="value"
              qid="create-rr-form-rr"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && (selectedScopeType.value == 'process_owner' || selectedScopeType.value == 'system_owner' || selectedScopeType.value == 'activity_owner' || selectedScopeType.value == 'third_party_owner')">
          <label qid="create-p-users-label">
            {{ $t('processes.create.owners_label') }}
          </label>
          <multiselect
              v-model="selectedUsers"
              :options="users"
              :multiple="true"
              :taggable="false"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              @search-change="loadUsers"
              track-by="slug"
              :placeholder="$t('processes.create.owners_placeholder')"
              label="name"
              qid="create-p-users-select"
          >
            <template slot="tag" slot-scope="props">
              <Avatar :object="props.option" size="xs"></Avatar>
              <span class="ml-2">
                {{ props.option.name }}
              </span>
            </template>
            <template slot="option" slot-scope="props">

            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_group'">
          <label qid="create-p-pg-label">
            {{ $t('processes.create.process_group_label') }}
          </label>
          <multiselect
              v-model="selectedProcessGroups"
              :options="processGroups"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcessGroups"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('processes.create.process_group_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'process_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.process_label') }}
          </label>
          <multiselect
              v-model="selectedProcesses"
              :options="processes"
              :multiple="true"
              :taggable="false"
              @search-change="loadProcesses"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.process_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'system_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.system_label') }}
          </label>
          <multiselect
              v-model="selectedSystems"
              :options="systems"
              :multiple="true"
              :taggable="false"
              @search-change="loadSystems"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.system_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'activity_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.activity_label') }}
          </label>
          <multiselect
              v-model="selectedActivities"
              :options="activities"
              :multiple="true"
              :taggable="false"
              @search-change="loadActivities"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.activity_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
        <b-form-group v-if="selectedScopeType && selectedScopeType.value && selectedScopeType.value == 'third_party_name'">
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.third_party_label') }}
          </label>
          <multiselect
              v-model="selectedThirdParties"
              :options="thirdParties"
              :multiple="true"
              :taggable="false"
              @search-change="loadThirdParties"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.third_party_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeRiskModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadRiskFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>


    <!-- Soa report modal -->
    <b-modal ref="soa-modal" hide-footer @hide="closeSoaModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.soa_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.framework_label') }}
          </label>
          <multiselect
              v-model="selectedFramework"
              :options="frameworks"
              :multiple="false"
              :taggable="false"
              @search-change="loadFrameworks"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.framework_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeSoaModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadSoaFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>

    <!-- Framework implementation report modal -->
    <b-modal ref="framework-modal" hide-footer @hide="closeFrameworkModal">
      <div class="d-block text-center">
        <h4 qid="add-ac-title">
          {{$t('reports.framework_implementation_report')}}
        </h4>
      </div>
      <b-alert v-if="modalError.length"
               show
               variant="danger"
               qid="create-ac-error"
      >
        {{ modalError }}
      </b-alert>

      <div class="form-container">
        <b-form-group>
          <label qid="create-p-pg-label">
            {{ $t('reports.modal.framework_label') }}
          </label>
          <multiselect
              v-model="selectedFrameworks"
              :options="frameworks"
              :multiple="true"
              :taggable="false"
              @search-change="loadFrameworks"
              :select-label="$t('system.dropdown_select_label')"
              :selected-label="$t('system.dropdown_selected_label')"
              :deselect-label="$t('system.dropdown_deselect_label')"
              :placeholder="$t('reports.modal.framework_placeholder')"
              label="name"
              track-by="slug"
              qid="create-p-form-pg"
          ></multiselect>
        </b-form-group>
      </div>
      <div class="text-right">
        <b-button class="mt-3"
                  variant="secondary"
                  @click="closeFrameworkModal"
                  qid="create-rr-cancel-button"
        >
          {{ $t('risk_scenarios.threat.create.cancel') }}
        </b-button>
        <b-button @click="downloadFrameworkFile()"
                  class="mt-3 ml-3"
                  variant="success"
                  qid="create-rr-submit-button"
        >
          {{ $t('risk_scenarios.threat.create.submit') }}
        </b-button>
      </div>
    </b-modal>
    <a href="" id="download-link" download=""></a>
  </div>
</template>

<script>
  import piincHttp from '@/util/configureAxios.js';
  import Avatar from '@/components/Avatar.vue';


  export default {
    name: 'Reports',
    data: function() {
      return {
        breadcrumbItems: [
          {
            text: this.$t('dashboard.title'),
            to: '/'
          },
          {
            text: this.$t('maintenance.title'),
            to: '/maintenance'
          },
          {
            text: this.$t('maintenance.reports.title'),
            active: true
          }
        ],
        error: '',
        loading: true,
        disablePage: false,
        loadingMessage: '',
        articleReport: {},
        riskReport: {},
        systemReport: {},
        thirdPartyReport: {},
        processRiskReport: {},
        processOverviewReport: {},
        riskControlReport: {},
        appendixRiskReport: {},
        soaReport: {},
        appendixSoaReport: {},
        appendixImplementationReport: {},
        user: {},
        clients: {},
        successMessage: '',
        selectedScopeType: [],
        selectedScope: [],
        options: [],
        modalError: '',

        processes: [],
        systems: [],
        activities: [],
        frameworks: [],
        thirdParties: [],
        selectedProcesses: [],
        selectedSystems: [],
        selectedActivities: [],
        selectedThirdParties: [],
        users: [],
        selectedUsers: [],
        processGroups: [],
        selectedProcessGroups: [],
        selectedRiskType: [],
        selectedScopeArea: [],
        selectedFramework: [],
        selectedFrameworks: [],
        processScopeTypes: [
          { name: this.$t('reports.scope_types.process_owner'), value: 'process_owner' },
          { name: this.$t('reports.scope_types.process_name'), value: 'process_name' },
          { name: this.$t('reports.scope_types.process_group'), value: 'process_group' },
        ],
        systemScopeTypes: [
          { name: this.$t('reports.scope_types.system_owner'), value: 'system_owner' },
          { name: this.$t('reports.scope_types.system_name'), value: 'system_name' },
        ],
        activityScopeTypes: [
          { name: this.$t('reports.scope_types.activity_owner'), value: 'activity_owner' },
          { name: this.$t('reports.scope_types.activity_name'), value: 'activity_name' },
        ],
        thirdPartyScopeTypes: [
          { name: this.$t('reports.scope_types.third_party_owner'), value: 'third_party_owner' },
          { name: this.$t('reports.scope_types.third_party_name'), value: 'third_party_name' },
        ],
        riskTypes: [
          { name: this.$t('risk_scenarios.risk_type.business_risk'), value: 'business_risk' },
          { name: this.$t('risk_scenarios.risk_type.privacy_risk'), value: 'privacy_risk' },
        ],
        scopeAreas: [
          { name: this.$t('risk_scenarios.type.process'), value: 'process' },
          { name: this.$t('risk_scenarios.type.activity'), value: 'activity' },
          { name: this.$t('risk_scenarios.type.system'), value: 'system' },
          { name: this.$t('risk_scenarios.type.third_party'), value: 'third_party' }
        ],
        processingRoles: [],
        processingRoleOptions: [
          { name: this.$t('activities.processing_role.data_processor_label'), value: 'Data processor' },
          { name: this.$t('activities.processing_role.data_controller_label'), value: 'Data controller' }
        ],
      }
    },
    components: {
      Avatar
    },
    computed: {
      getClient() {
        return this.$store.state.client
      }
    },
    watch: {
      getClient: function () {
        this.loadReports()
      }
    },
    methods: {
      getLoadingMessage() {
        let self = this;
      },
      loadMe() {
        // Keep reference in variable becauce of async functions
        let self = this;
        let selectedClient;

        // Call api/me for logged in user details
        piincHttp.get('users/me').then(function(response) {
          self.user = response.data.user;
          self.clients = response.data.clients;
          self.user.clients = self.clients;
          // Check if we have selected client in local storage
          let clientSlug = localStorage.getItem('client');
          if (clientSlug) {
            let currentClient = false;
            _.forEach(self.clients, function(client) {
              if(client.slug === clientSlug){
                currentClient = client;
                selectedClient = client;
              }
            })
          }

          piincHttp.get('users/me/permissions/' + selectedClient.slug).then(function(response) {
            selectedClient.user_permissions = response.data.user_permissions
            selectedClient.user_roles = response.data.user_roles

            piincHttp.get('clients/' + selectedClient.slug + '/trial').then(function(response) {

              selectedClient.onTrial = response.data.on_trial
              selectedClient.subscribed = response.data.subscribed
              selectedClient.trial_ends_at = response.data.trial_ends_at
              selectedClient.subscription_status = response.data.subscription_status
              localStorage.setItem('clientObject', JSON.stringify(selectedClient));
              localStorage.setItem('client', selectedClient.slug);
              self.$store.commit('storeClient', selectedClient);

            }, function() {});
          }, function() {});

        }, function() {

        });
      },
      resetObjects() {
        this.selectedProcesses = [],
        this.selectedProcessGroups = []
        this.selectedUsers = []
        this.selectedSystems = []
        this.selectedThirdParties = []
      },
      openProcessRiskModal() {
        this.selectedScopeType = []
        this.selectedProcesses = []
        this.selectedProcessGroups = []
        this.selectedUsers = []
        this.loadProcessGroups();
        this.loadProcesses();
        this.loadUsers();
        this.$refs['process-risk-modal'].show()
      },
      closeProcessRiskModal() {
        this.error = ''
        this.$refs['process-risk-modal'].hide()
      },
      openRiskModal() {
        this.selectedScopeType = []
        this.selectedProcesses = []
        this.selectedProcessGroups = []
        this.selectedUsers = []
        this.selectedRiskType = []
        this.selectedScopeArea = []
        this.loadProcessGroups();
        this.loadProcesses();
        this.loadSystems();
        this.loadActivities();
        this.loadThirdParties();
        this.loadUsers();
        this.$refs['risk-modal'].show()
      },
      closeRiskModal() {
        this.modalError = ''
        this.$refs['risk-modal'].hide()
      },
      openArticleModal() {
        this.selectedScopeType = []
        this.selectedProcesses = []
        this.selectedLanguage = []
        this.selectedProcessGroups = []
        this.selectedUsers = []
        this.loadProcessGroups();
        this.loadProcesses();
        this.loadUsers();
        this.$refs['article-modal'].show()
      },
      closeArticleModal() {
        this.modalError = ''
        this.$refs['article-modal'].hide()
      },
      openSoaModal() {
        this.selectedFramework = []
        this.loadFrameworks();
        this.$refs['soa-modal'].show()
      },
      closeSoaModal() {
        this.modalError = ''
        this.$refs['soa-modal'].hide()
      },
      openFrameworkModal() {
        this.selectedFrameworks = []
        this.loadFrameworks();
        this.$refs['framework-modal'].show()
      },
      closeFrameworkModal() {
        this.modalError = ''
        this.$refs['framework-modal'].hide()
      },
      openSystemModal() {
        this.selectedScopeType = []
        this.selectedUsers = []
        this.selectedSystems = []
        this.loadUsers();
        this.loadSystems();
        this.$refs['system-modal'].show()
      },
      closeSystemModal() {
        this.modalError = ''
        this.$refs['system-modal'].hide()
      },
      openProcessModal() {
        this.selectedScopeType = []
        this.selectedProcesses = []
        this.selectedProcessGroups = []
        this.selectedUsers = []
        this.loadProcessGroups();
        this.loadProcesses();
        this.loadUsers();
        this.$refs['process-modal'].show()
      },
      closeProcessModal() {
        this.modalError = ''
        this.$refs['process-modal'].hide()
      },
      openThirdPartyModal() {
        this.selectedScopeType = []
        this.selectedUsers = []
        this.selectedThirdParties = []
        this.loadUsers();
        this.loadThirdParties();
        this.$refs['third-party-modal'].show()
      },
      closeThirdPartyModal() {
        this.modalError = ''
        this.$refs['third-party-modal'].hide()
      },
      resetLoadingMessage() {
        this.loadingMessage = ''
      },
      downloadArticleFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();
        let usersArray = []
        let processesArray = []
        let processGroupsArray = []
        let processingRolesArray = []

        if (this.selectedUsers && this.selectedUsers.length) {
            for (var i = 0; i < this.selectedUsers.length; i++) {
              usersArray.push(this.selectedUsers[i].id);
            }
        }
        if (this.selectedProcesses && this.selectedProcesses.length) {
          for (var i = 0; i < this.selectedProcesses.length; i++) {
            processesArray.push(this.selectedProcesses[i].id);
          }
        }
        if (this.selectedProcessGroups && this.selectedProcessGroups.length) {
          for (var i = 0; i < this.selectedProcessGroups.length; i++) {
            processGroupsArray.push(this.selectedProcessGroups[i].id);
          }
        }

        if (this.processingRoles && this.processingRoles.length) {
          for (var i = 0; i < this.processingRoles.length; i++) {
            processingRolesArray.push(this.processingRoles[i].value);
          }
        }

        if (
          ((this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && this.selectedProcesses.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && this.selectedProcessGroups.length)) || (this.selectedScopeType && !this.selectedScopeType.value) || this.selectedScopeType == null
        ) {
          piincHttp.get('processes/generate-reports', { params:
            {
              users: usersArray,
              processes: processesArray,
              process_groups: processGroupsArray,
              client: this.getClient.slug,
              lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en',
              processing_roles: processingRolesArray
            }
          }).then(function(response) {
            self.disablePage = false;
            self.resetLoadingMessage();
            if (response.status == 200) {
              self.successMessage = self.$t('reports.success_message');
            }
            self.$refs['article-modal'].hide()
            self.loadMe();
            self.loadReports();
          }, function(error) {
            self.modalError = self.$t('reports.something_went_wrong');
            self.disablePage = false;
            self.resetLoadingMessage();
          });

        } else {
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && !this.selectedProcesses.length) {
            self.modalError = self.$t('reports.process_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && !this.selectedProcessGroups.length) {
            self.modalError = self.$t('reports.process_group_required');
          }
        }

      },
      downloadSoaFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();

        if (this.selectedFramework && this.selectedFramework.id) {
          piincHttp.get('frameworks/generate-reports', { params:
            {
              framework: this.selectedFramework.id,
              client: this.getClient.slug
            }
          }).then(function(response) {
            self.disablePage = false;
            self.resetLoadingMessage();
            if (response.status == 200) {
              self.successMessage = self.$t('reports.success_message');
            }
            self.$refs['soa-modal'].hide()
            self.loadMe();
            self.loadReports();
          }, function(error) {
            self.modalError = self.$t('reports.something_went_wrong');
            self.disablePage = false;
            self.resetLoadingMessage();
          });

        } else {
          self.modalError = self.$t('reports.framework_required');
        }

      },
      downloadFrameworkFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();

        let frameworks = []

        if (this.selectedFrameworks && this.selectedFrameworks.length) {
          for (var i = 0; i < this.selectedFrameworks.length; i++) {
            frameworks.push(this.selectedFrameworks[i].id);
          }
        }

        piincHttp.get('frameworks/generate-framework-reports', { params:
          {
            frameworks: frameworks,
            client: this.getClient.slug
          }
        }).then(function(response) {
          self.disablePage = false;
          self.resetLoadingMessage();
          if (response.status == 200) {
            self.successMessage = self.$t('reports.success_message');
          }
          self.$refs['framework-modal'].hide()
          self.loadMe();
          self.loadReports();
        }, function(error) {
          self.modalError = self.$t('reports.something_went_wrong');
          self.disablePage = false;
          self.resetLoadingMessage();
        });

      },
      downloadThirdPartyFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();
        let usersArray = []
        let thirdPartiesArray = []
        let processingRolesArray = []

        if (this.processingRoles && this.processingRoles.length) {
          for (var i = 0; i < this.processingRoles.length; i++) {
            processingRolesArray.push(this.processingRoles[i].value);
          }
        }
        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            usersArray.push(this.selectedUsers[i].id);
          }
        }
        if (this.selectedThirdParties && this.selectedThirdParties.length) {
          for (var i = 0; i < this.selectedThirdParties.length; i++) {
            thirdPartiesArray.push(this.selectedThirdParties[i].id);
          }
        }

        if (
          ((this.selectedScopeType && this.selectedScopeType.value == 'third_party_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'third_party_name' && this.selectedThirdParties && this.selectedThirdParties.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'third_party_processing_role' && this.processingRoles && this.processingRoles.length) ||
          (this.selectedScopeType && !this.selectedScopeType.value) || this.selectedScopeType == null)
        ) {

          piincHttp.get('third-parties/generate-reports', { params:
            {
              users: usersArray,
              third_parties: thirdPartiesArray,
              client: this.getClient.slug,
              processing_roles: processingRolesArray
            }
          }).then(function(response) {
            self.disablePage = false;
            self.resetLoadingMessage();
            if (response.status == 200) {
              self.successMessage = self.$t('reports.success_message');
            }
            self.$refs['third-party-modal'].hide()
            self.loadMe();
            self.loadReports();
          }, function(error) {
            self.modalError = self.$t('reports.something_went_wrong');
            self.disablePage = false;
            self.resetLoadingMessage();
          });
        } else {
          if (this.selectedScopeType && this.selectedScopeType.value == 'third_party_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'third_party_name' && this.selectedThirdParties && !this.selectedThirdParties.length) {
            self.modalError = self.$t('reports.third_party_required');
          }
        }
      },
      downloadSystemFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();
        let usersArray = []
        let systemsArray = []

        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            usersArray.push(this.selectedUsers[i].id);
          }
        }
        if (this.selectedSystems && this.selectedSystems.length) {
          for (var i = 0; i < this.selectedSystems.length; i++) {
            systemsArray.push(this.selectedSystems[i].id);
          }
        }

        if (
          ((this.selectedScopeType && this.selectedScopeType.value == 'system_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'system_name' && this.selectedSystems && this.selectedSystems.length)) || (this.selectedScopeType && !this.selectedScopeType.value) || this.selectedScopeType == null
        ) {
          piincHttp.get('systems/generate-reports', { params:
            {
              users: usersArray,
              systems: systemsArray,
              client: this.getClient.slug
            }
          }).then(function(response) {
            self.disablePage = false;
            self.resetLoadingMessage();
            if (response.status == 200) {
              self.successMessage = self.$t('reports.success_message');
            }
            self.$refs['system-modal'].hide()
            self.loadMe();
            self.loadReports();
          }, function(error) {
            self.modalError = self.$t('reports.something_went_wrong');
            self.disablePage = false;
            self.resetLoadingMessage();
          });

        } else {
          if (this.selectedScopeType && this.selectedScopeType.value == 'system_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'system_name' && this.selectedSystems && !this.selectedSystems.length) {
            self.modalError = self.$t('reports.system_required');
          }
        }

      },
      downloadProcessRiskFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();
        let usersArray = []
        let processesArray = []
        let processGroupsArray = []

        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            usersArray.push(this.selectedUsers[i].id);
          }
        }
        if (this.selectedProcesses && this.selectedProcesses.length) {
          for (var i = 0; i < this.selectedProcesses.length; i++) {
            processesArray.push(this.selectedProcesses[i].id);
          }
        }
        if (this.selectedProcessGroups && this.selectedProcessGroups.length) {
          for (var i = 0; i < this.selectedProcessGroups.length; i++) {
            processGroupsArray.push(this.selectedProcessGroups[i].id);
          }
        }

        if (
          ((this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && this.selectedProcesses.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && this.selectedProcessGroups.length)) || (this.selectedScopeType && !this.selectedScopeType.value) || this.selectedScopeType == null
        ) {
          piincHttp.get('processes/generate-reports/risk', { params:
            {
              users: usersArray,
              processes: processesArray,
              process_groups: processGroupsArray,
              client: this.getClient.slug
            }
          }).then(function(response) {
            self.disablePage = false;
            self.resetLoadingMessage();
            if (response.status == 200) {
              self.successMessage = self.$t('reports.success_message');
            }
            self.$refs['process-risk-modal'].hide()
            self.loadMe();
            self.loadReports();
          }, function(error) {
            self.modalError = self.$t('reports.something_went_wrong');
            self.disablePage = false;
            self.resetLoadingMessage();
          });

        } else {
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && !this.selectedProcesses.length) {
            self.modalError = self.$t('reports.process_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && !this.selectedProcessGroups.length) {
            self.modalError = self.$t('reports.process_group_required');
          }

        }

      },
      downloadRiskFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();
        let usersArray = []
        let processesArray = []
        let activitiesArray = []
        let systemsArray = []
        let thirdPartiesArray = []
        let processGroupsArray = []

        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            usersArray.push(this.selectedUsers[i].id);
          }
        }
        if (this.selectedProcesses && this.selectedProcesses.length) {
          for (var i = 0; i < this.selectedProcesses.length; i++) {
            processesArray.push(this.selectedProcesses[i].id);
          }
        }
        if (this.selectedSystems && this.selectedSystems.length) {
          for (var i = 0; i < this.selectedSystems.length; i++) {
            systemsArray.push(this.selectedSystems[i].id);
          }
        }
        if (this.selectedActivities && this.selectedActivities.length) {
          for (var i = 0; i < this.selectedActivities.length; i++) {
            activitiesArray.push(this.selectedActivities[i].id);
          }
        }
        if (this.selectedThirdParties && this.selectedThirdParties.length) {
          for (var i = 0; i < this.selectedThirdParties.length; i++) {
            thirdPartiesArray.push(this.selectedThirdParties[i].id);
          }
        }
        if (this.selectedProcessGroups && this.selectedProcessGroups.length) {
          for (var i = 0; i < this.selectedProcessGroups.length; i++) {
            processGroupsArray.push(this.selectedProcessGroups[i].id);
          }
        }

        if ((this.selectedRiskType && this.selectedRiskType.value && this.selectedScopeArea && this.selectedScopeArea.value) && (
          ((this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && this.selectedProcesses.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'system_name' && this.selectedSystems && this.selectedSystems.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'system_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'activity_name' && this.selectedActivities && this.selectedActivities.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'activity_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'third_party_name' && this.selectedThirdParties && this.selectedThirdParties.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'third_party_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && this.selectedProcessGroups.length)) || (this.selectedScopeType && !this.selectedScopeType.value) || this.selectedScopeType == null
        ))
         {
          piincHttp.get('generate-reports/risk-control', { params:
            {
              risk_type: this.selectedRiskType && this.selectedRiskType.value ? this.selectedRiskType.value : '',
              scope_area: this.selectedScopeArea && this.selectedScopeArea.value ? this.selectedScopeArea.value : '',
              users: usersArray,
              processes: processesArray,
              systems: systemsArray,
              activities: activitiesArray,
              third_parties: thirdPartiesArray,
              process_groups: processGroupsArray,
              client: this.getClient.slug
            }
          }).then(function(response) {
            self.disablePage = false;
            self.resetLoadingMessage();
            if (response.status == 200) {
              self.successMessage = self.$t('reports.success_message');
            }
            self.$refs['risk-modal'].hide()
            self.loadMe();
            self.loadReports();
          }, function(error) {
            self.modalError = self.$t('reports.something_went_wrong');
            self.disablePage = false;
            self.resetLoadingMessage();
          });
        } else {
          this.modalError = '';
          if ((this.selectedRiskType && !this.selectedRiskType.value) || this.selectedRiskType == null) {
            this.modalError += this.$t('reports.risk_type_required')
          }
          if ((this.selectedScopeArea && !this.selectedScopeArea.value) || this.selectedScopeArea == null) {
            this.modalError += ' ' + this.$t('reports.scope_area_required')
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && !this.selectedProcesses.length) {
            self.modalError = self.$t('reports.process_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && !this.selectedProcessGroups.length) {
            self.modalError = self.$t('reports.process_group_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'third_party_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'third_party_name' && this.selectedThirdParties && !this.selectedThirdParties.length) {
            self.modalError = self.$t('reports.system_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'system_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'system_name' && this.selectedSystems && !this.selectedSystems.length) {
            self.modalError = self.$t('reports.system_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'activity_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'activity_name' && this.selectedActivities && !this.selectedActivities.length) {
            self.modalError = self.$t('reports.activity_required');
          }
        }
      },
      downloadProcessOverviewFile(){
        this.successMessage = ''
        let self = this;
        this.getLoadingMessage();
        let usersArray = []
        let processesArray = []
        let processGroupsArray = []

        if (this.selectedUsers && this.selectedUsers.length) {
          for (var i = 0; i < this.selectedUsers.length; i++) {
            usersArray.push(this.selectedUsers[i].id);
          }
        }
        if (this.selectedProcesses && this.selectedProcesses.length) {
          for (var i = 0; i < this.selectedProcesses.length; i++) {
            processesArray.push(this.selectedProcesses[i].id);
          }
        }
        if (this.selectedProcessGroups && this.selectedProcessGroups.length) {
          for (var i = 0; i < this.selectedProcessGroups.length; i++) {
            processGroupsArray.push(this.selectedProcessGroups[i].id);
          }
        }

        if (
          ((this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && this.selectedUsers.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && this.selectedProcesses.length) ||
          (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && this.selectedProcessGroups.length)) || (this.selectedScopeType && !this.selectedScopeType.value) || this.selectedScopeType == null
        ) {
          piincHttp.get('processes/generate-reports/overview', { params:
            {
              users: usersArray,
              processes: processesArray,
              process_groups: processGroupsArray,
              client: this.getClient.slug
            }
          }).then(function(response) {
            self.disablePage = false;
            self.resetLoadingMessage();
            if (response.status == 200) {
              self.successMessage = self.$t('reports.success_message');
            }
            self.$refs['process-modal'].hide()
            self.loadMe();
            self.loadReports();
          }, function(error) {
            self.modalError = self.$t('reports.something_went_wrong');
            self.disablePage = false;
            self.resetLoadingMessage();
          });

        } else {
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_owner' && this.selectedUsers && !this.selectedUsers.length) {
            self.modalError = self.$t('reports.owners_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_name' && this.selectedProcesses && !this.selectedProcesses.length) {
            self.modalError = self.$t('reports.process_required');
          }
          if (this.selectedScopeType && this.selectedScopeType.value == 'process_group' && this.selectedProcessGroups && !this.selectedProcessGroups.length) {
            self.modalError = self.$t('reports.process_group_required');
          }
        }
      },
      async downloadMedia(object){
        piincHttp.get('clients/' + this.getClient.slug + '/report/' + object.collection_name, {responseType: 'blob'}).then(function(response) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveBlob(new Blob([response.data]), object.file_name);
          } else {

            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.download = object.file_name;
            link.click();
            link.remove()
            window.URL.revokeObjectURL(url);
          }
        }, function() {});
      },
      loadReports(){
        let self = this;

        if (this.getClient && this.getClient.slug) {

          this.articleReport = {}
          this.riskReport = {}
          this.systemReport = {}
          this.thirdPartyReport = {}
          this.processRiskReport = {}
          this.processOverviewReport = {}
          this.appendixRiskReport = {}
          this.appendixSoaReport = {}
          this.appendixImplementationReport = {}
          this.soaReport = {}

          _.forEach(this.getClient.media, function(media) {

            if(media.collection_name == "article_report"){
              self.articleReport = media;
            }

            if(media.collection_name == "risk_control_report"){
              self.riskReport = media;
            }

            if(media.collection_name == "system_report"){
              self.systemReport = media;
            }

            if(media.collection_name == "third_party_report"){
              self.thirdPartyReport = media;
            }

            if(media.collection_name == "process_risk_report"){
              self.processRiskReport = media;
            }

            if(media.collection_name == "process_overview_report"){
              self.processOverviewReport = media;
            }

            if(media.collection_name == "risk_control_report_appendix"){
              self.appendixRiskReport = media;
            }

            if(media.collection_name == "framework_report"){
              self.soaReport = media;
            }

            if(media.collection_name == "framework_report_appendix"){
              self.appendixSoaReport = media;
            }
            if(media.collection_name == "framework_implementation_report"){
              self.appendixImplementationReport = media;
            }
          });
        }
      },
      loadUsers(query) {
        let self = this;
        piincHttp.get('users', { params:
          {
            client: this.getClient.id,
            keyword: query ? query : undefined,
            per_page: 50
          }
        }).then(function(response) {
          self.users = response.data.items
          if (self.users && !self.users.length && !query) {
            self.users.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadProcesses(query) {
        let self = this;
        piincHttp.get('processes', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.processes = response.data.items
          if (self.processes && !self.processes.length && !query) {
            self.processes.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadThirdParties(query) {
        let self = this;
        piincHttp.get('vendors', { params:
          {
            client: this.getClient.slug,
            search: query ? query : undefined
          }
        }).then(function(response) {
          self.thirdParties = response.data.items
          if (self.thirdParties && !self.thirdParties.length && !query) {
            self.thirdParties.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadActivities(query) {
        let self = this;
        piincHttp.get('activities', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.activities = response.data.items
          if (self.activities && !self.activities.length && !query) {
            self.activities.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },

      loadProcessGroups(query) {
        let self = this;
        piincHttp.get('process-groups', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.processGroups = response.data.items
          if (self.processGroups && !self.processGroups.length && !query) {
            self.processGroups.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadSystems(query) {
        let self = this;
        piincHttp.get('systems', { params:
          {
            client: this.getClient.slug,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.systems = response.data.items
          if (self.systems && !self.systems.length && !query) {
            self.systems.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      loadFrameworks(query) {
        let self = this;
        piincHttp.get('frameworks', { params:
          {
            client: this.getClient.slug,
            per_page: 100,
            keyword: query ? query : undefined
          }
        }).then(function(response) {
          self.frameworks = response.data.items
          if (self.frameworks && !self.frameworks.length && !query) {
            self.frameworks.push({name: self.$t('system.no_results'), $isDisabled: true})
          }
        }, function() {});
      },
      download() {
        let self = this;
        // Component accessed when going on route: base_url/downloads/{media}

        if (this.$route.params.media) {

          piincHttp.get('documents/' + this.$route.params.media).then(function(file) {

            piincHttp.get('documents/' + file.data.id + '/download', {responseType: 'blob'}).then(function(response) {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', file.data.file_name);

              link.click();
              link.remove()
              window.URL.revokeObjectURL(url);
            }, function() {});
          }, function() {});
        }
      }
    },
    created () {

      this.loadMe();
      this.download();
      if (this.getClient && this.getClient.slug) {
        this.loadReports();
      }

    }
  }
</script>
